import * as React from 'react';

import { Carousel, CarouselContent, CarouselItem, type CarouselApi } from '@/components/ui/carousel';

type BannerCardProps = {
	src: string;
	alt?: string;
	onClick?: () => void;
};

interface BannerCarouselNamespace {
	Card: React.FC<BannerCardProps>;
}

const BannerCarousel: React.FC<{ children: React.ReactNode }> & BannerCarouselNamespace = ({ children }) => {
	const [api, setApi] = React.useState<CarouselApi>();
	const [, setCurrent] = React.useState(0);
	const [, setCount] = React.useState(0);

	React.useEffect(() => {
		if (!api) return;
		setCount(api.scrollSnapList().length);
		setCurrent(api.selectedScrollSnap() + 1);
	}, [api]);

	return (
		<div className="mx-auto max-w-3xl items-center">
			<Carousel setApi={setApi} className="w-full overflow-auto">
				<CarouselContent>{children}</CarouselContent>
			</Carousel>
		</div>
	);
};

BannerCarousel.Card = ({ src, alt, onClick }: BannerCardProps) => {
	return (
		<CarouselItem key={alt} onClick={onClick}>
			<img src={src} alt={alt} className="size-full cursor-pointer object-cover" />
		</CarouselItem>
	);
};

export default BannerCarousel;
